import { useState } from "react";
import { Spinner } from "react-bootstrap";
import apiClient from "../../../lib/api";

const ButtonCheckout = ({ priceId, planName, mode = "subscription", buttonName = "Subscribe" }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handlePayment = async () => {
    setIsLoading(true);

    try {
      const res = await apiClient.post("/stripe/create-checkout", {
        priceId,
        mode,
        successUrl: window.location.href + "?status=success",
        cancelUrl: window.location.href,
      });

      window.location.href = res.url;
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  return (
    <button
      className={`shadow btn btn-primary btn-block tw-p-3 rounded-lg plausible-event-name=Purchase-${planName}`}
      onClick={() => handlePayment()}
    >
      {isLoading && <Spinner animation="border" size="sm" className="me-2" />}
      {buttonName}
    </button>
  );
};

export default ButtonCheckout;

import React, { Fragment } from "react";
import Link from "next/link";
import { useState } from "react";
import Pricing from "../../helpers/pricing-helpers";
import AppContext from "../../contexts/appContext/appContext";
import SEO from "../../components/common/seo";
import { SETTINGS_TAB } from "../../helpers/constants";
import { Check } from "react-feather";
import { useSession } from "next-auth/react";
import ButtonCheckout from "../../components/common/buttons/checkout-button";
import { useRouter } from "next/router";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { MinusIcon } from "@heroicons/react/20/solid";
import { CircleCheckIcon, InfoIcon } from "lucide-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PricingPage = ({}) => {
  const [profile, setProfile] = useState({});
  const { globalData } = React.useContext(AppContext);
  const { profile: globalProfileData = {} } = globalData;
  const [planType, setPlanType] = useState("yearly");
  const { data: session, status } = useSession();
  const router = useRouter();
  const [showBlackFridayDeal, setShowBlackFridayDeal] = useState(true);

  /////////// Lifetime deal code ///////////

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const faqs = [
    {
      question: "What one time payment means?",
      answer: "One time payment means you pay once and you can use it forever.",
    },
    {
      question: "Will I receive all future updates?",
      answer:
        "Yes, all future updates are included in this plan, ensuring you have access to the latest features and improvements.",
    },
    {
      question: "Do you provide a refund guarantee?",
      answer:
        "Yes, we offer a 30-day no-questions-asked refund policy, allowing you to request a full refund within 30 days of purchase if you are not satisfied with our product or service.",
    },
    {
      question: "Is it possible to upgrade or downgrade my lifetime plan?",
      answer:
        "Yes, you have the flexibility to upgrade or downgrade your lifetime plan. To facilitate this process, please reach out to our customer support team, and we will be happy to assist you with the necessary adjustments.",
    },
  ];

  const padLeft = (num) => {
    if (String(num).length > 2) {
      return num;
    }
    return String("00" + num).slice(-2);
  };

  const updateDealCountdown = () => {
    const countDownDate = new Date("2024-11-20T05:59:49.875Z").getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    if (distance < 0) {
      setShowBlackFridayDeal(false);
      return;
    }

    setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
    setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
    setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
    setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
  };

  React.useEffect(() => {
    if (!showBlackFridayDeal) {
      return; // Cancel interval if showBlackFridayDeal is false
    }

    updateDealCountdown();
    const interval = setInterval(updateDealCountdown, 1000);
    return () => clearInterval(interval);
  }, [showBlackFridayDeal]);

  /////////// Lifetime deal code ///////////

  const isLoading = () => {
    return status === "loading";
  };

  React.useEffect(() => {
    if (globalProfileData?.state === "results") {
      setProfile(globalProfileData?.data);
    }
  }, [globalProfileData]);

  const plans = Pricing.filter((o) => o.type === planType && o.name !== "Hobby");
  const label = planType === "monthly" ? "month" : "year";
  if (typeof window === "object" && window.Paddle) {
    const Paddle = window.Paddle;
    if (process.env.NEXT_PUBLIC_ENV === "stage") {
      Paddle.Environment.set("sandbox");
    }
    Paddle.Setup({
      vendor: parseInt(process.env.NEXT_PUBLIC_PADDLE_VENDOR_ID),
    });
  }

  const changePlan = (e) => {
    setPlanType(e ? "yearly" : "monthly");
  };

  const changePlanType = (type) => {
    setPlanType(type);
  };

  const isPaidUser = () => {
    // No discouts for now
    // return true;
    return profile && profile.plan && profile.plan !== "Free";
  };

  const isLifetimeUser = () => {
    return profile && profile.plan && profile.isLifetimePlan;
  };

  const isSelectedPlan = (planName) => {
    return profile && profile.plan && profile.plan === planName;
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  /////////// Lifetime deal code ///////////

  function FAQSeciont() {
    return (
      <div className="tw-bg-gray-100 tw-rounded-lg">
        <div className="tw-mx-auto tw-max-w-7xl tw-px-6 tw-mt-10 sm:tw-pt-16 sm:tw-pb-16 lg:tw-px-8 lg:tw-py-16">
          <div className="lg:tw-grid lg:tw-grid-cols-12 lg:tw-gap-8">
            <div className="lg:tw-col-span-5">
              <h2 className="tw-text-2xl tw-font-bold tw-leading-10 tw-tracking-tight tw-text-gray-900">
                Frequently asked questions
              </h2>
              <p className="tw-mt-4 tw-text-base tw-leading-7 tw-text-gray-600">
                Can’t find the answer you’re looking for? Reach out to our{" "}
                <a
                  href="mailto:support@notion-widgets.com"
                  className="tw-font-semibold tw-text-red-600 hover:tw-text-red-500"
                >
                  customer support
                </a>{" "}
                team.
              </p>
            </div>
            <div className="tw-mt-10 lg:tw-col-span-7 lg:tw-mt-0">
              <dl className="tw-space-y-10">
                {faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className="tw-text-base tw-font-semibold tw-leading-7 tw-text-gray-900">{faq.question}</dt>
                    <dd className="tw-mt-2 tw-text-base tw-leading-7 tw-text-gray-400">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function getDealCountdown() {
    return (
      <div className="tw-gap-0 tw-text-center tw-auto-cols-max tw-bg-red-500 tw-p-2 tw-rounded-lg">
        <p className="tw-font-bold tw-text-lg tw-text-white">Deal ends in</p>
        <div className="tw-grid tw-grid-flow-col tw-gap-2 tw-text-center tw-text-white tw-text-sm">
          <div className="tw-flex tw-flex-col tw-p-1 tw-bg-red-400 tw-rounded-md tw-w-20">
            <span className="tw-font-mono tw-text-xl">
              <span>{padLeft(days)}</span>
            </span>
            days
          </div>
          <div className="tw-flex tw-flex-col tw-p-1 tw-bg-red-400 tw-rounded-md tw-w-20">
            <span className="tw-font-mono tw-text-xl">
              <span>{padLeft(hours)}</span>
            </span>
            hours
          </div>
          <div className="tw-flex tw-flex-col tw-p-1 tw-bg-red-400 tw-rounded-md tw-w-20">
            <span className="tw-font-mono tw-text-xl">
              <span>{padLeft(minutes)}</span>
            </span>
            min
          </div>
          <div className="tw-flex tw-flex-col tw-p-1 tw-bg-red-400 tw-rounded-md tw-w-20">
            <span className="tw-font-mono tw-text-xl">
              <span>{padLeft(seconds)}</span>
            </span>
            sec
          </div>
        </div>
      </div>
    );
  }

  function LifetimePricing() {
    return (
      <div className="text-center row align-items-end">
        {plans.map(({ id, name, currency, price, anchorPrice, priceDetails, features, stripePlanId, mostPopular }) => (
          <div className="mb-5 col-lg-4 mb-lg-0" key={`plan_${id}`}>
            <div
              className={classNames(
                isPaidUser()
                  ? isSelectedPlan(name)
                    ? "tw-border-red-300 tw-border-4"
                    : "tw-border-gray-200 tw-border"
                  : mostPopular
                  ? "tw-border-red-300 tw-border-2"
                  : "tw-border-gray-200 tw-border",
                "py-5 bg-white shadow tw-pr-3 rounded-3 tw-border-solid"
              )}
            >
              <h1 className="mb-2 h6 text-uppercase font-weight-bold">{name}</h1>
              {isSelectedPlan(name) && (
                <p className="mb-4 tw-text-[10px] text-uppercase font-weight-bold">
                  <span className="tw-bg-[#ff4a00] tw-rounded-full tw-px-2.5 tw-py-1 tw-text-white">current plan</span>
                </p>
              )}
              <h2 className="h1 font-weight-bold">
                {currency}
                {anchorPrice} <small style={{ fontSize: "18px" }}></small>
                <span className="px-2 tw-line-through tw-text-gray-300">
                  {currency}
                  {price}
                </span>
              </h2>
              <small className="mt-0 mb-0 text-black-50">{priceDetails}</small>
              <div className="mx-auto my-4 custom-separator bg-primary"></div>

              <ul className="my-5 text-small text-start tw-h-64">
                {features.map((feature, index) => (
                  <li className="tw-mb-3 list-group-item" key={`prcing_${planType}_${index}`}>
                    <Check size={18} className="mr-2 text-primary" /> {feature}
                  </li>
                ))}
              </ul>
              {isLoading() || globalProfileData?.state === "init" ? (
                <p>...</p>
              ) : (
                <div>
                  {session && profile && profile.plan && profile.plan !== "Free" ? (
                    <Link href={`/settings?selectedTab=${SETTINGS_TAB.PLANSSUB}`}>Manage Subscription</Link>
                  ) : (
                    <ButtonCheckout planName={name} priceId={stripePlanId} mode="payment" buttonName="Buy now" />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }

  /////////// Lifetime deal code ///////////

  function showBlackFridayPrice(id) {
    if (isPaidUser()) {
      return false;
    }
    return showBlackFridayDeal && planType === "yearly" && id !== "yearly_basic";
  }

  function SubscriptionPricing() {
    return (
      <div className="text-center row align-items-end">
        {plans.map(
          ({
            id,
            name,
            currency,
            price,
            anchorPrice,
            priceDetails,
            features,
            stripePlanId,
            mostPopular,
            priceDetailsBlackFriday,
          }) => (
            <div className="mb-5 col-lg-4 mb-lg-0" key={`plan_${id}`}>
              <div
                className={classNames(
                  isPaidUser()
                    ? isSelectedPlan(name)
                      ? "tw-border-red-300 tw-border-4"
                      : "tw-border-gray-200 tw-border"
                    : mostPopular
                    ? "tw-border-red-300 tw-border-2"
                    : "tw-border-gray-200 tw-border",
                  "py-5 bg-white shadow tw-pr-3 rounded-3 tw-border-solid"
                )}
              >
                <h1 className="mb-2 h6 text-uppercase font-weight-bold">{name}</h1>
                {isSelectedPlan(name) && (
                  <p className="mb-4 tw-text-[10px] text-uppercase font-weight-bold">
                    <span className="tw-bg-[#ff4a00] tw-rounded-full tw-px-2.5 tw-py-1 tw-text-white">
                      current plan
                    </span>
                  </p>
                )}
                {(anchorPrice || showBlackFridayPrice(id)) && !isPaidUser() ? (
                  <h2 className="h1 font-weight-bold">
                    <span className="px-2 tw-line-through tw-text-gray-300">
                      {currency}
                      {price}
                    </span>
                    {currency}
                    {showBlackFridayPrice(id) ? (Math.round(price * 0.8 * 100) / 100).toFixed(2) : anchorPrice}
                    <small style={{ fontSize: "18px" }}>/ month</small>
                  </h2>
                ) : (
                  <h2 className="h1 font-weight-bold">
                    {currency}
                    {price} <small style={{ fontSize: "18px" }}>/ month</small>
                  </h2>
                )}
                <small className="mt-0 mb-0 text-black-50">
                  {showBlackFridayPrice(id) ? priceDetailsBlackFriday : priceDetails}
                </small>
                <div className="mx-auto my-4 custom-separator bg-primary"></div>
                <ul className="my-5 text-small text-start tw-h-64">
                  {features.map((feature, index) => (
                    <li className="tw-mb-3 list-group-item" key={`prcing_${planType}_${index}`}>
                      <Check size={18} className="mr-2 text-primary" /> {feature}
                    </li>
                  ))}
                </ul>
                {isLoading() || globalProfileData?.state === "init" ? (
                  <p>...</p>
                ) : (
                  <div>
                    {session && profile && profile.plan && profile.plan !== "Free" ? (
                      <Link href={`/settings?selectedTab=${SETTINGS_TAB.PLANSSUB}`}>Manage Subscription</Link>
                    ) : (
                      <ButtonCheckout planName={name} priceId={stripePlanId} mode="subscription" />
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </div>
    );
  }
  const freePlan = {
    name: "Free",
    price: "0",
    anchorPrice: "",
    priceDetails: "",
    currency: "$",
    features: [`10 widgets`, "500 views per month", "Buttons, Counters, Productivity widgets"],
    mostPopular: false,
  };

  function FreePricing() {
    return (
      <div className="text-center tw-w-full">
        <div className="tw-w-full tw-mb-5 tw-px-4" key="free">
          <div
            className={classNames(
              isPaidUser()
                ? isSelectedPlan(freePlan.name)
                  ? "tw-border-red-300 tw-border-4"
                  : "tw-border-gray-200 tw-border"
                : "tw-border-gray-200 tw-border",
              "tw-pt-4 bg-white tw-shadow-md tw-pr-3 rounded-3 tw-border-solid"
            )}
          >
            <h2 className="mb-2 h6 text-uppercase font-weight-bold">{freePlan.name}</h2>
            {isSelectedPlan(freePlan.name) && (
              <p className="mb-4 tw-text-[10px] text-uppercase font-weight-bold">
                <span className="tw-bg-[#ff4a00] tw-rounded-full tw-px-2.5 tw-py-1 tw-text-white">current plan</span>
              </p>
            )}
            <h2 className="h3 font-weight-bold">
              {freePlan.currency}
              {freePlan.price} <small style={{ fontSize: "18px" }}>/ month</small>
            </h2>
            <small className="mt-0 mb-0 text-black-50">{freePlan.priceDetails}</small>
            <div className="mx-auto my-4 custom-separator bg-primary"></div>
            <ul className="tw-text-base tw-text-start">
              {freePlan.features.map((feature, index) => (
                <li className="tw-mb-3 list-group-item" key={`prcing_${planType}_${index}`}>
                  <Check size={18} className="mr-2 text-primary" /> {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function ApiOffer() {
    return (
      <div className="tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231] tw-px-6 tw-rounded-lg">
        <div className="tw-flex tw-py-4 tw-bg-opacity-5" style={{ backgroundImage: "url('/glamorous.svg')" }}>
          <div className="tw-flex-none tw-w-40 tw-mx-auto tw-text-center tw-self-center tw-text-8xl">🎉</div>
          <div className="tw-grow tw-ml-11 tw-items-start">
            <h2 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-white">#API Plan</h2>
            <ul className="text-small text-start tw-text-white tw-px-0 tw-opacity-80 tw-mt-5">
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> API Access
              </li>
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Custom Watermark
              </li>
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Access to all current features
              </li>
              <li className="tw-mb-1 list-group-item">
                <Check size={18} className="text-white" /> Unlimited widdgets and apps
              </li>
            </ul>
          </div>
          <div className="tw-flex-none tw-w-auto tw-self-center">
            <button
              className="rounded shadow btn btn-primary tw-animate-bounce"
              onClick={() => {
                router.push("https://tally.so/r/wk6k5e");
              }}
            >
              Register Now
            </button>
          </div>
        </div>
      </div>
    );
  }

  const tiers = [
    {
      name: "Free",
    },
    {
      name: "Basic",
    },
    {
      name: "Pro",
    },
    {
      name: "Ultimate",
    },
  ];

  const sections = [
    {
      name: "Features",
      features: [
        {
          name: "Embed on websites or Notion pages",
          tiers: { Free: "Unlimited", Basic: "Unlimited", Pro: "Unlimited", Ultimate: "Unlimited" },
        },
        { name: "Widgets & Apps", tiers: { Free: "10", Basic: "30", Pro: "70", Ultimate: "300" } },
        {
          name: "Views per month (coming soon)",
          info: "The number of views is the number of times a widget loads on a webpage, Notion page or via a share link. Exceeding this monthly limit will temporarily deactivate all widgets.",
          tiers: { Free: "500", Basic: "5,000", Pro: "50,000", Ultimate: "250,000" },
        },
        { name: "Embed widgets on website", tiers: { Free: false, Basic: false, Pro: true, Ultimate: true } },
        { name: "Removed Branding", tiers: { Free: false, Basic: true, Pro: true, Ultimate: true } },
        { name: "Beta Features", tiers: { Free: false, Basic: false, Pro: true, Ultimate: true } },
        {
          name: "Support",
          tiers: { Free: "Email", Basic: "Email", Pro: "Priority Email", Ultimate: "Priority Email" },
        },
      ],
    },
    {
      name: "Counter Widgets",
      features: [
        { name: "Counter", link: "widget/counter", tiers: { Free: true, Basic: true, Pro: true, Ultimate: true } },
        {
          name: "Habit Counter",
          link: "widget/habit-counter",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        { name: "KPI", link: "widget/kpi", tiers: { Free: false, Basic: false, Pro: true, Ultimate: true } },
        {
          name: "Life Progress",
          link: "widget/year-month-week-day-progress",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        { name: "Page View", link: "widget/page-view", tiers: { Free: true, Basic: true, Pro: true, Ultimate: true } },
        {
          name: "Simple Count Down",
          link: "widget/simple-count-down",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "Word Clock",
          link: "widget/word-clock",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "World Clock",
          link: "widget/world-clock",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
      ],
    },
    {
      name: "Button Widgets",
      features: [
        { name: "Button", link: "widget/button", tiers: { Free: true, Basic: true, Pro: true, Ultimate: true } },
        {
          name: "Like Button",
          link: "widget/like-button",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "Upvote",
          link: "widget/upvote-button",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "Paypal Purchase Button",
          link: "widget/paypal-button",
          tiers: { Free: false, Basic: false, Pro: true, Ultimate: true },
        },
      ],
    },
    {
      name: "Weather Widgets",
      features: [
        {
          name: "Weather Forecast",
          link: "widget/weather",
          tiers: { Free: false, Basic: false, Pro: true, Ultimate: true },
        },
        {
          name: "Weather Round",
          link: "widget/weather-round",
          tiers: { Free: false, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "Weather Square",
          link: "widget/weather-square",
          tiers: { Free: false, Basic: true, Pro: true, Ultimate: true },
        },
      ],
    },
    {
      name: "Productivity Widgets",
      features: [
        {
          name: "Inspirational Quotes",
          link: "widget/inspirational-quotes",
          tiers: { Free: true, Basic: true, Pro: true, Ultimate: true },
        },
        { name: "Pomodoro", link: "app/pomodoro", tiers: { Free: false, Basic: true, Pro: true, Ultimate: true } },
        {
          name: "Recurring Tasks",
          link: "app/recurring-tasks",
          tiers: { Free: false, Basic: true, Pro: true, Ultimate: true },
        },
        {
          name: "Time Tracker",
          link: "widget/time-tracker",
          tiers: { Free: false, Basic: false, Pro: true, Ultimate: true },
        },
        { name: "To-Do list", link: "widget/todo", tiers: { Free: true, Basic: true, Pro: true, Ultimate: true } },
        { name: "Automation (coming soon)", tiers: { Free: false, Basic: false, Pro: true, Ultimate: true } },
        { name: "AI Builder (coming soon)", tiers: { Free: false, Basic: false, Pro: false, Ultimate: true } },
      ],
    },
  ];

  function PricingDetailSection() {
    return (
      <div className="tw-px-6 tw-pt-16 tw-mx-auto tw-max-w-2xl md:tw-pt-24 lg:tw-max-w-7xl lg:tw-px-8">
        <table className="tw-w-full tw-text-left max-md:tw-hidden">
          <caption className="tw-sr-only">Pricing plan comparison</caption>
          <colgroup>
            <col className="tw-w-2/6" />
            <col className="tw-w-1/6" />
            <col className="tw-w-1/6" />
            <col className="tw-w-1/6" />
            <col className="tw-w-1/6" />
          </colgroup>
          <thead>
            <tr>
              <td className="tw-p-0" />
              {tiers.map((tier) => (
                <th key={tier.name} scope="col" className="tw-p-0">
                  <div className="h6 text-uppercase font-weight-bold !tw-text-base">
                    {tier.name} <span className="tw-sr-only">plan</span>
                  </div>
                </th>
              ))}
            </tr>
            <tr>
              <th className="p-0" />
              {tiers.map((tier) => (
                <td key={tier.name} className="tw-px-0 tw-pt-3 tw-pb-0">
                  {isSelectedPlan(tier.name) && (
                    <p className="tw-text-[10px] text-uppercase font-weight-bold">
                      <span className="tw-bg-[#ff4a00] tw-rounded-full tw-px-2.5 tw-py-1 tw-text-white">
                        current plan
                      </span>
                    </p>
                  )}
                </td>
              ))}
            </tr>
          </thead>
          {sections.map((section) => (
            <tbody key={section.name} className="group">
              <tr>
                <th scope="colgroup" colSpan={5} className="tw-px-0 tw-pt-10 tw-pb-0 group-first-of-type:tw-pt-5">
                  <div className="tw-px-4 tw-py-3 tw--mx-4 tw-font-semibold tw-bg-[#ff4a00]/30 tw-rounded-lg tw-text-sm/6 tw-text-gray-950">
                    {section.name}
                  </div>
                </th>
              </tr>
              {section.features.map((feature) => (
                <tr
                  key={feature.name}
                  className="tw-border-b tw-border-gray-100 last:tw-border-none hover:tw-bg-gray-50"
                >
                  <th scope="row" className="tw-px-0 tw-py-4 tw-font-normal tw-text-gray-600 tw-text-sm/6">
                    {feature.link ? <Link href={feature.link}>{feature.name}</Link> : feature.name}
                    {feature.info && (
                      <OverlayTrigger overlay={<Tooltip id={feature.name}>{feature.info}</Tooltip>}>
                        <InfoIcon className="tw-w-4 tw-h-4 tw-inline-block tw-align-middle tw-ml-1" />
                      </OverlayTrigger>
                    )}
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.name} className="tw-p-4 max-sm:tw-text-center">
                      {typeof feature.tiers[tier.name] === "string" ? (
                        <>
                          <span className="tw-sr-only">{tier.name} includes:</span>
                          <span className="tw-text-sm/6 tw-text-gray-950">{feature.tiers[tier.name]}</span>
                        </>
                      ) : (
                        <>
                          {feature.tiers[tier.name] === true ? (
                            <CircleCheckIcon
                              aria-hidden="true"
                              className="tw-inline-block tw-w-5 tw-h-5 tw-fill-[#ff4a00]/20 tw-stroke-1"
                            />
                          ) : (
                            <MinusIcon aria-hidden="true" className="tw-inline-block tw-w-4 tw-h-4 tw-fill-gray-400" />
                          )}

                          <span className="tw-sr-only">
                            {feature.tiers[tier.name] === true
                              ? `Included in ${tier.name}`
                              : `Not included in ${tier.name}`}
                          </span>
                        </>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          ))}
        </table>
        <TabGroup className="md:tw-hidden">
          <TabList className="tw-flex">
            {tiers.map((tier) => (
              <Tab
                key={tier.name}
                className="tw-w-1/4 tw-border-b-4 tw-border-l-0 tw-border-t-0 tw-border-r-0 tw-border-solid tw-border-gray-100 tw-py-4 tw-bg-transparent tw-text-base/8 tw-font-medium h6 text-uppercase font-weight-bold data-[selected]:tw-border-[#ff4a00] [&:not([data-focus])]:focus:tw-outline-none"
              >
                {tier.name}
              </Tab>
            ))}
          </TabList>
          <TabPanels as={Fragment}>
            {tiers.map((tier) => (
              <TabPanel key={tier.name}>
                {sections.map((section) => (
                  <Fragment key={section.name}>
                    <div className="tw-px-6 tw-py-3 tw--mx-6 tw-mt-10 tw-font-semibold tw-bg-[#ff4a00]/10 tw-rounded-lg tw-text-sm/6 tw-text-gray-950 group-first-of-type:tw-mt-5">
                      {section.name}
                    </div>
                    <dl>
                      {section.features.map((feature) => (
                        <div
                          key={feature.name}
                          className="tw-grid tw-grid-cols-2 tw-py-4 tw-border-b tw-border-gray-100 last:tw-border-none"
                        >
                          <dt className="tw-font-normal tw-text-gray-600 tw-text-sm/6">
                            {feature.link ? <Link href={feature.link}>{feature.name}</Link> : feature.name}
                          </dt>
                          <dd className="tw-text-center">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <span className="tw-text-sm/6 tw-text-gray-950">{feature.tiers[tier.name]}</span>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CircleCheckIcon
                                    aria-hidden="true"
                                    className="tw-inline-block tw-w-5 tw-h-5 tw-fill-[#ff4a00]/20 tw-stroke-1"
                                  />
                                ) : (
                                  <MinusIcon
                                    aria-hidden="true"
                                    className="tw-inline-block tw-w-4 tw-h-4 tw-fill-gray-400"
                                  />
                                )}

                                <span className="tw-sr-only">{feature.tiers[tier.name] === true ? "Yes" : "No"}</span>
                              </>
                            )}
                          </dd>
                        </div>
                      ))}
                    </dl>
                  </Fragment>
                ))}
              </TabPanel>
            ))}
          </TabPanels>
        </TabGroup>
      </div>
    );
  }

  // function lifetimeDeals() {
  //   return (
  //     <div className="tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231] tw-px-6 tw-rounded-lg">
  //       <div className="tw-flex tw-py-4 tw-bg-opacity-5" style={{ backgroundImage: "url('/glamorous.svg')" }}>
  //         <div className="tw-flex-none tw-w-40 tw-mx-auto tw-text-center tw-self-center tw-text-8xl">🎉</div>
  //         <div className="tw-grow tw-ml-11 tw-items-start">
  //           <h2 className="tw-text-2xl tw-font-bold tw-tracking-tight tw-text-white">
  //             Get Your Lifetime Deal 🎉
  //           </h2>
  //           <ul className="text-small text-start tw-text-white tw-px-0 tw-opacity-80 tw-mt-5">
  //             <li className="tw-mb-1 list-group-item">
  //               <Check size={18} className="text-white" /> Pay once, use forever
  //             </li>
  //             <li className="tw-mb-1 list-group-item">
  //               <Check size={18} className="text-white" /> 30 days money back guarantee without any question
  //             </li>
  //             <li className="tw-mb-1 list-group-item">
  //               <Check size={18} className="text-white" /> All future updates
  //             </li>
  //           </ul>
  //         </div>
  //         <div className="tw-flex-none tw-w-auto tw-self-center">
  //           <button
  //             className="rounded shadow btn btn-primary tw-animate-bounce"
  //             onClick={() => {
  //               router.push("/rushDeal");
  //             }}
  //           >
  //             Get Lifetime Deal
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <section>
      <SEO
        title={"pricing for using | Notion Widgets"}
        description="Our pricing list for using notion widgets."
        path={"https://www.notion-widgets.com/pricing"}
        canonical={"https://www.notion-widgets.com/pricing"}
      />
      <div className="container py-5">
        <header className="mb-2 text-center">
          <div className="row">
            <div className="mx-auto col-lg-7">
              <h1>Notion Widgets Pricing</h1>
            </div>
            <div className="mx-auto mt-3 col-lg-7">
              <p className="tw-text-xl tw-text-gray-500">Choose a plan that works best for you!</p>
            </div>
            <div className="d-flex justify-content-center">
              {/* <div className="custom-control custom-switch custom-switch-xl">
                {!isLifetimeUser() && (
                  // <div className="d-flex justify-content-center">
                  //   <div className="tw-mr-2">Monthly</div>
                  //   <div className="tw-mt-0">
                  //     <SwitchCheckbox
                  //       isActive={planType === "yearly"}
                  //       manageVisibility={false}
                  //       onChange={changePlan}
                  //     />
                  //   </div>
                  //   <div className="tw-ml-2">
                  //     Yearly{" "}
                  //     <small className={planType == "yearly" ? "text-primary tw-font-bold" : "text-black-50"}>
                  //       (Save up to 50%)
                  //     </small>
                  //   </div>
                  // </div>
                  
                )}
              </div> */}
              <div className="d-flex justify-content-center">
                <div className="tw-flex tw-space-x-1 tw-rounded-xl tw-bg-[#ff4a00]/30 tw-p-1 tw-h-16">
                  <button
                    className={classNames(
                      "tw-rounded-lg tw-w-[150px]",
                      planType === "monthly"
                        ? "tw-bg-white tw-text-bold tw-border-[#ff4a00]/40 tw-border-1 tw-shadow-sm"
                        : "tw-bg-transparent hover:tw-bg-white/60 tw-border-0"
                    )}
                    onClick={() => changePlanType("monthly")}
                  >
                    Monthly
                  </button>
                  <button
                    className={classNames(
                      "tw-rounded-lg tw-w-[150px]",
                      planType === "yearly"
                        ? "tw-bg-white tw-text-bold tw-border-[#ff4a00]/40 tw-border-1 tw-shadow-sm"
                        : "tw-bg-transparent hover:tw-bg-white/60 tw-border-0"
                    )}
                    onClick={() => changePlanType("yearly")}
                  >
                    <div className="tw-grid">
                      Yearly
                      <span className="tw-text-gray-500 tw-text-xs">
                        Save up to{" "}
                        {showBlackFridayPrice() ? <span className="tw-line-through tw-text-gray-400">20%</span> : "20%"}
                        {showBlackFridayPrice() && (
                          <span className="tw-pl-1 tw-font-bold tw-text-sm tw-text-black">40%</span>
                        )}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="mx-auto mt-3 mb-3">
              <div className="d-flex justify-content-center">{showBlackFridayPrice() && getDealCountdown()}</div>
            </div>
          </div>
        </header>

        {(planType === "monthly" || planType === "yearly") && SubscriptionPricing()}
        {planType === "lifetime" && LifetimePricing()}
        <div className="mt-5 row">
          {/* {!isPaidUser() && (
            <div className="py-3 mx-auto text-center text-white shadow col-12 tw-border-solid tw-border tw-border-primary rounded-3 w-50 bg-dark">
              🥳 Get <span className="p-1 mx-1 text-black bg-warning tw-text-xl">25%</span> for 12 months.
              Limited offer <span className="tw-text-yellow-300 tw-underline">only 2 seats left</span>{" "}
            </div>
          )} */}
        </div>
        <FreePricing />
        <PricingDetailSection />
        {/* <div className="mt-5 row">
          <div className="text-center col-12">
            <p className="text-muted">
              <strong className="pr-2">Note: </strong>
              Notion Widgets doesn't offer free trial. However, with a free account you can get{" "}
              <b>
                <u>one free widget</u>
              </b>{" "}
              to see how the widget works.
            </p>
          </div>
        </div> */}
        {planType === "lifetime" && FAQSeciont()}
      </div>
    </section>
  );
};

export default PricingPage;
